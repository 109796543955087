.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  padding: 40px 0;
  &-body {
    &-logo {
      a {
        display: block;
      }
    }
    &-menu {
      display: flex;
      justify-content: center;
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        width: 100%;
        li {
          margin: 0 15px;
          a {
            color: dark-neutral(0);
            font-size: 20px;
            white-space: nowrap;
            font-family: $font-family-2;
          }
          &.game,
          &.shop {
            position: relative;
            z-index: 1;
            text-shadow: rgb(255, 255, 255) 2px 0px 0px,
              rgb(255, 255, 255) 1.75517px 0.958851px 0px,
              rgb(255, 255, 255) 1.0806px 1.68294px 0px,
              rgb(255, 255, 255) 0.141474px 1.99499px 0px,
              rgb(255, 255, 255) -0.832294px 1.81859px 0px,
              rgb(255, 255, 255) -1.60229px 1.19694px 0px,
              rgb(255, 255, 255) -1.97998px 0.28224px 0px,
              rgb(255, 255, 255) -1.87291px -0.701566px 0px,
              rgb(255, 255, 255) -1.30729px -1.5136px 0px,
              rgb(255, 255, 255) -0.421592px -1.95506px 0px,
              rgb(255, 255, 255) 0.567324px -1.91785px 0px,
              rgb(255, 255, 255) 1.41734px -1.41108px 0px,
              rgb(255, 255, 255) 1.92034px -0.558831px 0px;
            a {
              font-weight: bold;
            }
            &::after {
              content: '';
              width: 100%;
              height: 25px;
              position: absolute;
              left: 0;
              bottom: -10px;
              background-repeat: no-repeat;
              background-size: cover;
              z-index: -1;
            }
          }
          &.game {
            a {
              color: primary(500) !important;
            }
            &::after {
              background-image: url('/images/game-line.png');
            }
          }
          &.shop {
            a {
              color: primary(300) !important;
            }
            &::after {
              background-image: url('/images/shop-line.png');
            }
          }
        }
      }
    }
    .desktop-menu-button {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      color: dark-neutral(0);
      width: 100px;
      height: 100px;
      line-height: 100px;
      .c-icon {
        font-size: 30px;
      }
    }
  }
  &--home {
    background: linear-gradient(
      180deg,
      rgba(dark-neutral-rgb(0), 0.5) 0%,
      rgba(dark-neutral-rgb(0), 0) 100%
    );
    .desktop-menu-button {
      color: light-neutral(0);
    }
    .header-body {
      .header-body-menu {
        ul {
          li {
            a {
              color: light-neutral(0);
            }
          }
        }
      }
    }
  }
}
@media #{$media-sm} {
  .header {
    &-body {
      &-logo {
        .image {
          width: 132px;
        }
      }
    }
  }
}
