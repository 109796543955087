.events-calendar {
  width: 535px;
  max-width: 100%;
  border: 2px solid light-neutral(0);
  border-radius: 50px;
  padding: 30px 50px;
  box-sizing: border-box;
  position: relative;
  color: light-neutral(0);
  z-index: 1;
  margin-top: 100px;
  figure {
    position: absolute;
    width: 355px;
    height: 200px;
    top: -200px;
    right: 0;
    z-index: -1;
    overflow: hidden;
  }
  .navigation {
    display: flex;
    justify-content: space-between;
    &-buttons {
      display: flex;
      align-items: center;
      button {
        background: transparent;
        border: none;
        cursor: pointer;
        color: light-neutral(0);
      }
    }
  }
  .react-calendar {
    padding: 15px 0;
    margin: 15px 0;
    border-bottom: 1px solid rgba(light-neutral-rgb(0), 0.5);
    border-top: 1px solid rgba(light-neutral-rgb(0), 0.5);
    button {
      &:disabled,
      &[disabled] {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &__month-view {
      &__weekdays {
        &__weekday {
          text-align: center;
          abbr {
            text-decoration: none;
          }
        }
      }
      &__days {
        &__day {
          width: calc(100% / 7);
          padding: 0;
          position: relative;
          overflow: visible !important;
          border: none;
          color: light-neutral(0);
          flex: none !important;
          display: flex;
          border-radius: 100%;
          background: transparent;
          cursor: pointer;
          abbr {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            z-index: 2;
            font-weight: bold;
            font-size: 20px;
          }
          > div {
            width: 100%;
            height: 50px;
            flex: 1;
            margin: 5px;
            z-index: 1;
            position: relative;
            background: dark-neutral(70);
            border-radius: 100%;
            border: 1px solid light-neutral(0);
            .event {
              width: 100%;
              height: 100%;
              position: relative;
              border-radius: 100%;
              &-list {
                position: absolute;
                bottom: 0;
                right: -5px;
                display: flex;
                &-item {
                  width: 15px;
                  height: 15px;
                  border-radius: 100%;
                  background-color: dark-neutral(0);
                  border: 1px solid light-neutral(0);
                  margin-left: -10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .category {
    display: flex;
    &-item {
      display: flex;
      align-items: center;
      margin: 0 10px;
      span {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        border: 1px solid light-neutral(0);
        margin-right: 5px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &::after {
    content: '';
    background-color: dark-neutral(0);
    opacity: 0.2;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 50px;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1368px) {
  .events-calendar {
    margin: 0;
    padding: 30px 15px;
    width: 400px;
    .react-calendar {
      &__month-view {
        &__weekdays {
          &__weekday {
            abbr {
              text-decoration: none;
            }
          }
        }
        &__days {
          &__day {
            abbr {
              font-size: 14px;
            }
            > div {
              width: 100%;
              margin: 1px;
            }
          }
        }
      }
    }
  }
}

@media #{$media-lg} {
  .events-calendar {
    margin: 0;
    padding: 30px 15px;
    width: 445px;
    .react-calendar {
      &__month-view {
        &__weekdays {
          &__weekday {
            text-align: center;
            abbr {
              text-decoration: none;
            }
          }
        }
        &__days {
          &__day {
            abbr {
              font-size: 14px;
            }
            > div {
              width: 100%;

              height: 55px;
              margin: 1px;
            }
          }
        }
      }
    }
  }
}

@media #{$media-sm} {
  .events-calendar {
    margin: 0;
    padding: 30px 15px;
    .react-calendar {
      &__month-view {
        &__weekdays {
          &__weekday {
            text-align: center;
            abbr {
              text-decoration: none;
            }
          }
        }
        &__days {
          &__day {
            abbr {
              font-size: 14px;
            }
            > div {
              width: 100%;

              height: 45px;
              margin: 1px;
            }
          }
        }
      }
    }
  }
}
