.pagination {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  li {
    margin: 0 2.5px;
    button {
      width: 35px;
      height: 35px;
      background: primary(200);
      border: none;
      color: light-neutral(0);
      opacity: 0.5;
      border-radius: 5px;
      cursor: pointer;
    }
    &.active {
      button {
        opacity: 1;
        cursor: auto;
      }
    }
  }
}
