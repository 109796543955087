.banner {
  position: relative;
  &-image {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
  }
  &-video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      border-radius: 100%;
      width: 60px;
      height: 60px;
      border: 3px solid light-neutral(0);
      z-index: 4;
      transition: all, 0.4s;
      &--close {
        left: 50px;
        top: 95%;
      }
    }
    video {
      width: max-content;
      height: 100%;
      padding: 0;
    }
  }
}

@media #{$media-sm} {
  .banner {
    &-image {
      height: 100vh;
      .image {
        height: inherit;
        img {
          height: inherit;
        }
      }
    }
  }
}
