.video-list-detail {
  &-head {
    font-family: $font-family-2;
    font-weight: 400;
    .title {
      font-size: 30px;
    }
    a {
      font-weight: bold;
    }
  }
  .video {
    .detail {
      text-align: left;
      color: dark-neutral(0);
    }
  }
}

@media #{$media-sm} {
  .video-list-detail {
    .video {
      .detail {
        padding: 15px 0;
      }
    }
  }
}
