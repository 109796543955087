.videos {
  position: relative;
  padding: 0 0 75px 0;
  color: light-neutral(0);
  background-color: primary(300);
  &::after {
    content: '';
    width: 110%;
    height: 200px;
    position: absolute;
    background-color: primary(300);
    z-index: -1;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100%;
  }
  .head {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 5;
    margin-bottom: 75px;
    .container {
      position: relative;
    }
    .title {
      width: 100%;
      text-align: center;
      font-size: 50px;
      font-family: $font-family-2;
    }
    .link {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media #{$media-lg} {
  .videos {
    padding: 25px 0;
    .head {
      margin-bottom: 25px;
      .title {
        font-size: 30px;
      }
      .link {
        position: relative;
        top: auto;
        transform: none;
        margin: 0 auto;
        display: block;
        width: max-content;
      }
    }
  }
}

@media #{$media-sm} {
  .videos {
    padding: 25px 0;
    .head {
      margin-bottom: 25px;
      .title {
        font-size: 30px;
      }
      .link {
        position: relative;
        top: auto;
        transform: none;
        margin: 0 auto;
        display: block;
        width: max-content;
      }
    }
  }
}
