.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  background-color: rgba(light-neutral-rgb(900), 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all, 0.4s;
  pointer-events: none;
  opacity: 0;
  &-content {
    position: relative;
    width: 80%;
    border-radius: 50px;
    overflow: hidden;
    transform: scale(0);
    transition: all, 0.4s;
    background-color: dark-neutral(0);
    filter: drop-shadow(0 0 35px dark-neutral(30));
    height: 70vh;
    .video {
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .image {
      width: 100%;
      height: 100%;
      filter: grayscale(0);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .detail {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      background: dark-neutral(0);
      padding: 100px;
      box-sizing: border-box;
      color: light-neutral(0);
      text-shadow: 1px 1px 1px dark-neutral(0);
      background: linear-gradient(
        0deg,
        rgba(dark-neutral-rgb(0), 1) 0%,
        rgba(dark-neutral-rgb(0), 0) 100%
      );
      .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        font-family: $font-family-2;
      }
    }
  }
  &-close {
    right: 30px;
    top: 30px;
    padding: 0;
    position: absolute;
    background-color: rgba(dark-neutral-rgb(0), 0.5) !important;
    border: 4px solid rgba(light-neutral-rgb(0), 0.8);
    border-radius: 100%;
    outline: none !important;
    box-shadow: none !important;
    z-index: 2;
    width: 60px;
    height: 60px;
    font-size: 30px;
    line-height: 52px;
    font-family: $font-family-2;
    &:hover {
      background-color: primary(500) !important;
      color: light-neutral(0);
    }
  }
  &--active {
    opacity: 1;
    pointer-events: auto;
    transition: all, 0.4s;
    .popup-content {
      transform: scale(1);
      transition: all, 0.4s;
    }
  }
}

@media #{$media-sm} {
  .popup {
    &-content {
      width: 90%;
      .detail {
        padding: 35px;
       bottom: 50px;
        height: 50%;
        overflow: scroll;
        z-index: 2;
      }
      &::after {
        content: '';
        height: 300px;
        width: 100%;
        background-color: dark-neutral(0);
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(var(--DN0-RGB), 1) 0%, rgba(var(--DN0-RGB), 0) 100%);
        z-index: 1;
        pointer-events: none;
      }
    }
  }
}
