.content {
  padding: 200px 0 400px;
  background-size: cover;
  background-position: center center;
  margin-bottom: -200px;
  h1 {
    font-size: 50px;
    color: primary(300);
    font-weight: 400;
    margin: 30px 0;
  }
}

@media #{$media-sm} {
  .content {
    padding: 150px 0 200px;
    margin-bottom: -100px;
    background-size: 800px;
    .col {
      padding: 15px;
    }
    h1 {
      font-size: 30px;
    }
  }
}
