.character {
  border-radius: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-end;
  border: 3px solid light-neutral(0);
  height: 700px;
  figure {
    .image {
      img {
        max-height: 490px;
      }
    }
  }
  .detail {
    text-align: center;
    padding: 0 50px 50px 50px;
    color: light-neutral(0);
    .title {
      font-family: $font-family-2;
      text-transform: uppercase;
      font-size: 80px;
      font-weight: bold;
      text-shadow: rgb(255, 255, 255) 2px 0px 0px,
        rgb(255, 255, 255) 1.75517px 0.958851px 0px,
        rgb(255, 255, 255) 1.0806px 1.68294px 0px,
        rgb(255, 255, 255) 0.141474px 1.99499px 0px,
        rgb(255, 255, 255) -0.832294px 1.81859px 0px,
        rgb(255, 255, 255) -1.60229px 1.19694px 0px,
        rgb(255, 255, 255) -1.97998px 0.28224px 0px,
        rgb(255, 255, 255) -1.87291px -0.701566px 0px,
        rgb(255, 255, 255) -1.30729px -1.5136px 0px,
        rgb(255, 255, 255) -0.421592px -1.95506px 0px,
        rgb(255, 255, 255) 0.567324px -1.91785px 0px,
        rgb(255, 255, 255) 1.41734px -1.41108px 0px,
        rgb(255, 255, 255) 1.92034px -0.558831px 0px;
    }
    .desc {
      font-size: 15px;
    }
  }
}

@media #{$media-sm} {
  .character {
    border-radius: 75px;
    height: 475px;
    figure {
      .image {
        img {
          max-height: 250px;
        }
      }
    }
    .detail {
      .title {
        font-size: 45px;
      }
    }
  }
}
