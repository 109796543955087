.dropdown {
  position: relative;
  z-index: 2;
  button {
    background-color: light-neutral(0);
    padding: 0 30px;
    line-height: 40px;
    border: none;
    border-radius: 25px;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
    .c-icon {
      transition: all, 0.2s ease;
    }
    &.active {
      background-color: primary(500);
      color: light-neutral(0);
      .c-icon {
        transform: rotate(-180deg);
      }
    }
  }
  &-menu {
    position: absolute;
    left: 0;
    display: block !important;
    background: light-neutral(0);
    border-radius: 10px;
    top: 50px;
    li {
      margin: 0 !important;
      button {
        width: 100%;
        border-radius: 0;
        line-height: 30px !important;
        .button-text {
          color: dark-neutral(0) !important;
          font-weight: 400;
          font-size: 13px !important;
          white-space: nowrap;
        }
        &:hover {
          .button-text {
            color: light-neutral(0) !important;
          }
        }
        &:active,
        &:target {
          .button-text {
            color: light-neutral(0) !important;
          }
        }
      }
      &:first-child {
        button {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      &:last-child {
        button {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      ul {
        left: calc(100% + 10px) !important;
        transform: none !important;
        top: 0 !important;
        padding: 0 !important;
        li {
          button {
            background: light-neutral(0) !important;
            &:hover {
              background: primary(500) !important;
            }
          }
        }
      }
      .calendar {
        left: 0 !important;
        transform: none !important;
        top: 50px !important;
        position: absolute;
      }
    }
  }
}
