.news {
  margin: 150px 0;
  &-title {
    font-size: 30px;
    color: primary(400);
    font-family: $font-family-2;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 15px 0;
  }
  &-swiper {
    width: 100%;
    height: 100%;
    .swiper-slide {
      figure {
        .image {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .detail {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        line-clamp: 7;
        -webkit-box-orient: vertical;
        .title {
          font-size: 20px;
          font-family: $font-family-2;
          margin-bottom: 15px;
        }
      }
    }
  }
  .swiper-pagination {
    width: 50%;
    right: 0;
    left: auto;
    text-align: left;
    display: flex;
    &-bullet {
      width: max-content;
      height: max-content;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-end;
      justify-content: center;
      background: transparent;
      font-size: 30px;
      font-family: $font-family-2;
      color: primary(400);
      height: 50px;
      width: 30px;
      text-align: center;
      .number {
        display: none;
        margin-bottom: 10px;
        width: 100%;
      }
      .dot {
        width: 20px;
        height: 20px;
        background: dark-neutral(0);
        border-radius: 100%;
      }
      &-active {
        .number {
          display: block;
        }
        .dot {
          background: primary(400);
        }
      }
    }
  }
  .swiper-navigation {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    .swiper-button {
      position: relative !important;
      width: 35px;
      height: 35px;
      margin: 0 2.5px;
      right: auto;
      left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: primary(400);
      border-radius: 100%;
      color: light-neutral(0);
      &::after {
        font-size: 12px;
        line-height: 35px;
      }
    }
  }
}

@media #{$media-lg} {
  .news {
    margin: 0 0 150px 0;
    &-swiper {
      padding-bottom: 50px;
      .swiper-slide {
        display: block;
        justify-content: center;
        align-items: center;
        .detail {
          .title {
            font-size: 20px;
            font-family: $font-family-2;
            margin: 15px 0;
          }
        }
      }
    }
    .swiper-pagination {
      width: auto;
      left: 0;
    }
  }
}

@media #{$media-sm} {
  .news {
    margin: 0 0 150px 0;
    &-swiper {
      padding-bottom: 50px;
      .swiper-slide {
        display: block;
        justify-content: center;
        align-items: center;
        .detail {
          .title {
            font-size: 20px;
            font-family: $font-family-2;
            margin: 15px 0;
          }
        }
      }
    }
    .swiper-pagination {
      width: auto;
      left: 0;
    }
    .swiper-navigation {
    }
  }
}
