.about {
  position: relative;
  .desc {
    position: relative;
    z-index: 2;
  }
  .shape {
    position: absolute;
    top: 0;
    width: 50%;
    z-index: 1;

    .image {
      width: 100%;
    }
    &--left {
      left: -50%;
      transform: translateX(50%);
    }
    &--right {
      right: -50%;
      transform: translateX(-50%);
    }
  }
  .teams {
    margin: 100px 0 0 0;
    text-align: center;
    .team {
      .detail {
        .title {
          margin: 15px 0;
          font-weight: bold;
          font-size: 25px;
          span {
            display: block;
            font-weight: 300;
            font-size: 15px;
          }
        }
      }
    }
  }
}
