.news-list-item {
  padding: 15px;
  button {
    padding: 0;
    background: transparent !important;
    border: none;
    outline: none;
    box-shadow: none !important;
    figure {
      overflow: hidden;
      border-radius: 30px;
      position: relative;
      .image {
        transform: scale(1);
        transition: all, 0.4s ease-out;
      }
      .c-icon {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0.7;
      }
    }
    .detail {
      padding: 15px 35px;
      font-size: 15px;
      color: dark-neutral(0);
      text-align: left;
      .title {
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: bold;
      }
    }
    &:hover {
      figure {
        .image {
          transform: scale(1.1);
          transition: all, 1s ease-out;
        }
      }
    }
  }
}
@media #{$media-sm} {
  .news-list-item {
    button {
      .detail {
        padding: 15px 0;
      }
    }
  }
}
