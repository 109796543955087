@use 'sass:math';

@mixin row-align($breakpoint) {
  $map: (
    baseline: baseline,
    center: center,
    end: flex-end,
    start: flex-start,
    stretch: stretch,
  );

  @each $key, $value in $map {
    .row#{$breakpoint}-align-#{$key} {
      align-items: $value;
    }
  }
}

@mixin row-content($breakpoint) {
  $map: (
    around: space-around,
    between: space-between,
    center: center,
    end: flex-end,
    evenly: space-evenly,
    start: flex-start,
  );

  @each $key, $value in $map {
    .row#{$breakpoint}-content-#{$key} {
      align-content: $value;
    }
  }
}

@mixin row-direction($breakpoint) {
  $map: (
    row: row,
    row-reverse: row-reverse,
    column: column,
    column-reverse: column-reverse,
  );

  @each $key, $value in $map {
    .row#{$breakpoint}-direction-#{$key} {
      flex-direction: $value;
    }
  }
}

@mixin row-gutter($breakpoint) {
  $map: (
    xs: 2,
    sm: 4,
    md: 8,
    lg: 12,
    xl: 16,
    none: 0,
  );

  @each $key, $value in $map {
    .row#{$breakpoint}-gutter-#{$key} {
      margin: #{$value}px;

      & > .col {
        padding: #{$value}px;
        flex: 1 0 0%;
      }
    }
  }
}

@mixin row-justify($breakpoint) {
  $map: (
    around: space-around,
    between: space-between,
    center: center,
    end: flex-end,
    evenly: space-evenly,
    start: flex-start,
  );

  @each $key, $value in $map {
    .row#{$breakpoint}-justify-#{$key} {
      justify-content: $value;
    }
  }
}

@mixin row-wrap($breakpoint) {
  $map: (
    nowrap: nowrap,
    wrap: wrap,
    wrap-reverse: wrap-reverse,
  );

  @each $key, $value in $map {
    .row#{$breakpoint}-wrap-#{$key} {
      flex-wrap: $value;
    }
  }
}

@mixin column-size($breakpoint) {
  @for $index from 1 through 12 {
    $size: math.div(100%, math.div(12, $index));

    .col#{$breakpoint}-offset-#{$index} {
      margin-left: $size;
    }

    .col#{$breakpoint}-padding-0 {
      padding: 0;
    }

    .col#{$breakpoint}-#{$index} {
      flex-basis: $size;
      max-width: $size;
      box-sizing: border-box;
    }
  }

  .col#{$breakpoint}-offset-0 {
    margin-left: 0;
  }
}

@mixin column-align($breakpoint) {
  $map: (
    start: flex-start,
    center: center,
    end: flex-end,
  );

  @each $key, $value in $map {
    .col#{$breakpoint}-align-#{$key} {
      align-self: $value;
    }
  }
}

@mixin column-order($breakpoint) {
  .col#{$breakpoint}-order {
    &-first {
      order: -1;
    }

    &-last {
      order: 1;
    }
  }
}

@mixin generate($breakpoint: '') {
  @include row-align($breakpoint);
  @include row-content($breakpoint);
  @include row-direction($breakpoint);
  @include row-gutter($breakpoint);
  @include row-justify($breakpoint);
  @include row-wrap($breakpoint);
  @include column-size($breakpoint);
  @include column-align($breakpoint);
  @include column-order($breakpoint);
}

.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  &-fluid {
    width: 100%;
  }

  &-extended {
    width: 90%;
  }

  &-wide {
    width: 1320px;
  }

  &-medium {
    width: 1140px;
  }

  &-narrow {
    width: 960px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
  box-sizing: border-box;
}

.d-flex {
  display: flex;
}

.col {
  flex: 1 0 0%;
  padding: 0 15px;
  box-sizing: border-box;
  &-auto {
    flex: 0 0 auto;
    width: auto;
  }
}

@include generate('-xs');

@include media-sm {
  @include generate('-sm');
}

@include media-md {
  @include generate('-md');
}

@include media-lg {
  @include generate('-lg');
}

@include media-xl {
  @include generate('-xl');
}
