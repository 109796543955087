.panel {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
  width: 320px;
  height: 100vh;
  padding: 60px 30px;
  box-sizing: border-box;
  transition: all, 0.4s ease-in-out;
  font-family: $font-family-2;
  &--hide {
    right: -320px;
  }
  &-action {
    .menu-button {
      background: transparent;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      border: 1px solid light-neutral(0);
      color: light-neutral(0);
      transition: all, 0.4s ease-in-out;
      cursor: pointer;
      &:hover {
        background: light-neutral(0);
        color: primary(500);
      }
    }
  }
  .navigation {
    margin: 25px 0;
    &-menu {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          width: max-content;
          margin: 25px 0;
          a {
            color: light-neutral(0);
            display: block;
            font-size: 20px;
          }
          &.game,
          &.shop {
            position: relative;
            z-index: 1;
            text-shadow: rgb(255, 255, 255) 2px 0px 0px,
              rgb(255, 255, 255) 1.75517px 0.958851px 0px,
              rgb(255, 255, 255) 1.0806px 1.68294px 0px,
              rgb(255, 255, 255) 0.141474px 1.99499px 0px,
              rgb(255, 255, 255) -0.832294px 1.81859px 0px,
              rgb(255, 255, 255) -1.60229px 1.19694px 0px,
              rgb(255, 255, 255) -1.97998px 0.28224px 0px,
              rgb(255, 255, 255) -1.87291px -0.701566px 0px,
              rgb(255, 255, 255) -1.30729px -1.5136px 0px,
              rgb(255, 255, 255) -0.421592px -1.95506px 0px,
              rgb(255, 255, 255) 0.567324px -1.91785px 0px,
              rgb(255, 255, 255) 1.41734px -1.41108px 0px,
              rgb(255, 255, 255) 1.92034px -0.558831px 0px;
            a {
              font-weight: bold;
            }
            &::after {
              content: '';
              width: 100%;
              height: 25px;
              position: absolute;
              left: 0;
              bottom: -10px;
              background-repeat: no-repeat;
              background-size: cover;
              z-index: -1;
            }
          }
          &.game {
            a {
              color: primary(500) !important;
            }
            &::after {
              background-image: url('/images/game-line.png');
            }
          }
          &.shop {
            a {
              color: primary(300) !important;
            }
            &::after {
              background-image: url('/images/shop-line.png');
            }
          }
        }
      }
    }
  }
  &::before {
    content: '';
    background-color: rgba(primary-rgb(500), 0.8);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0px 5px 35px 0 dark-neutral(0);
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    z-index: -1;
  }
}
