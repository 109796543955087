.events {
  position: relative;
  &--home {
    z-index: 2;
    padding: 150px 0;
    background: primary(300);
    border-bottom-left-radius: 150px;
    border-bottom-right-radius: 150px;
    .head-title {
      font-size: 50px;
      font-family: $font-family-2;
      color: light-neutral(0);
    }
  }
}

@media #{$media-lg} {
  .events {
    &--home {
      padding: 15px 0 40px 0;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      .head-title {
        font-size: 30px;
        margin: 15px 0;
      }
    }
  }
}

@media #{$media-sm} {
  .events {
    &--home {
      padding: 15px 0;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      .head-title {
        font-size: 30px;
        margin: 15px 0;
      }
    }
  }
}
