.footer {
  position: relative;
  padding: 75px 0;
  color: light-neutral(0);
  z-index: 1;
  &-logo {
    margin-top: -150px;
  }
  &-about {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-family-2;
    }
    margin: 75px 0;
  }
  &-menu {
    display: flex;
    justify-content: center;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      width: 100%;
      li {
        margin: 0 15px;
        a {
          color: light-neutral(0);
          font-size: 20px;
          white-space: nowrap;
          font-family: $font-family-2;
        }
        &.game,
        &.shop {
          position: relative;
          z-index: 1;
          text-shadow: rgb(255, 255, 255) 2px 0px 0px,
            rgb(255, 255, 255) 1.75517px 0.958851px 0px,
            rgb(255, 255, 255) 1.0806px 1.68294px 0px,
            rgb(255, 255, 255) 0.141474px 1.99499px 0px,
            rgb(255, 255, 255) -0.832294px 1.81859px 0px,
            rgb(255, 255, 255) -1.60229px 1.19694px 0px,
            rgb(255, 255, 255) -1.97998px 0.28224px 0px,
            rgb(255, 255, 255) -1.87291px -0.701566px 0px,
            rgb(255, 255, 255) -1.30729px -1.5136px 0px,
            rgb(255, 255, 255) -0.421592px -1.95506px 0px,
            rgb(255, 255, 255) 0.567324px -1.91785px 0px,
            rgb(255, 255, 255) 1.41734px -1.41108px 0px,
            rgb(255, 255, 255) 1.92034px -0.558831px 0px;
          a {
            font-weight: bold;
          }
          &::after {
            content: '';
            width: 100%;
            height: 25px;
            position: absolute;
            left: 0;
            bottom: -10px;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
          }
        }
        &.game {
          a {
            color: primary(500);
          }
          &::after {
            background-image: url('/images/game-line.png');
          }
        }
        &.shop {
          a {
            color: primary(300);
          }
          &::after {
            background-image: url('/images/shop-line.png');
          }
        }
      }
    }
  }
  &::after {
    content: '';
    width: 120%;
    height: 100%;
    position: absolute;
    background-color: primary(500);
    z-index: -1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }
}

@media #{$media-lg} {
  .footer {
    margin: 0;
    &-logo {
      width: 250px;
      margin: -150px auto 0 auto;
    }
    &::after {
      border-top-left-radius: 175px;
      border-top-right-radius: 175px;
    }
    &-menu {
      ul {
        display: block;
        li {
          width: 100%;
          text-align: center;
          float: none;
          margin: 10px 0;
          box-sizing: border-box;
          display: block;
          &.game,
          &.shop {
            display: flex;
            margin: 5px auto;
            justify-content: center;
            width: max-content;
          }
          // &:nth-child(3) {
          //   margin-top: 50px;
          // }
        }
      }
    }
  }
}

@media #{$media-md} {
  .footer {
    margin: 0;
    &-logo {
      width: 250px;
      margin: -150px auto 0 auto;
    }
    &::after {
      border-top-left-radius: 175px;
      border-top-right-radius: 175px;
    }
    &-menu {
      ul {
        display: block;
        li {
          width: 100%;
          text-align: center;
          float: none;
          margin: 10px 0;
          box-sizing: border-box;
          display: block;
          &.game,
          &.shop {
            display: flex;
            margin: 5px auto;
            justify-content: center;
            width: max-content;
          }
          // &:nth-child(3) {
          //   margin-top: 50px;
          // }
        }
      }
    }
  }
}

@media #{$media-sm} {
  .footer {
    margin: 0;
    &-logo {
      width: 250px;
      margin: -150px auto 0 auto;
    }
    &::after {
      border-top-left-radius: 175px;
      border-top-right-radius: 175px;
    }
    &-menu {
      ul {
        display: block;
        li {
          width: 100%;
          text-align: center;
          float: none;
          margin: 10px 0;
          box-sizing: border-box;
          display: block;
          &.game,
          &.shop {
            display: flex;
            margin: 5px auto;
            justify-content: center;
            width: max-content;
            &::after {
              height: 33px;
            }
          }
          // &:nth-child(3) {
          //   margin-top: 50px;
          // }
          a {
            font-size: 25px;
          }
        }
      }
    }
  }
}
