.events-list {
  width: 100%;
  .swiper {
    height: 100%;
  }
  .filter {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      align-items: center;
      li {
        position: relative;
        margin: 0 5px;
        button {
          padding: 0 15px;
          margin: 0;
          min-width: fit-content;
          min-height: fit-content;
          line-height: 40px;
          background: transparent;
          color: dark-neutral(0);
          box-shadow: none !important;
          .button-text {
            display: flex;
            align-items: center;
            font-size: 20px;
            .c-icon {
              margin-left: 5px;
            }
          }
          &:hover {
            background-color: primary(500);
            color: light-neutral(0);
          }
          &:focus,
          &:target {
            background-color: primary(400);
            color: light-neutral(0);
          }
          &.active {
            background-color: primary(100);
            color: light-neutral(0);
          }
        }
        .calendar {
          width: 260px;
          position: absolute;
          background-color: light-neutral(0);
          border-radius: 15px;
          left: 50%;
          transform: translateX(-50%);
          padding: 15px;
          box-sizing: border-box;
          z-index: 9;
          .react-calendar {
            button {
              &:disabled,
              &[disabled] {
                opacity: 0.5;
                pointer-events: none;
              }
            }
            &__tile {
              &--active {
                background-color: primary(500);
                color: light-neutral(0);
              }
            }
            &__month-view {
              &__weekdays {
                margin-bottom: 5px;
                &__weekday {
                  text-align: center;
                  abbr {
                    text-decoration: none;
                    font-size: 12px;
                  }
                }
              }
              &__days {
                &__day {
                  width: calc(100 / 7);
                  padding: 0;
                  border: none;
                  line-height: 32px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 5px;
                  cursor: pointer;
                  abbr {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        ul {
          position: absolute;
          background-color: light-neutral(0);
          padding: 15px;
          box-sizing: border-box;
          border-radius: 15px;
          display: block;
          left: 50%;
          transform: translateX(-50%);
          z-index: 9;
          li {
            margin: 5px 0;
            width: 100%;
            button {
              width: 100%;
              background: primary(500);
              color: light-neutral(0);
            }
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .head-filter {
    a {
      padding: 0 15px;
      margin: 0;
      line-height: 40px;
      color: light-neutral(0);
      font-size: 20px;
      font-weight: bold;
      transition: all, 0.4s ease-out;
      border-radius: 15px;
      display: block;
      &:hover {
        background: primary(500);
        transition: all, 0.4s ease-in;
      }
    }
  }
  .alert {
    background-color: primary(200);
    color: light-neutral(0);
    border-radius: 5px;
    margin-top: 0;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    pointer-events: none;
    padding: 0 15px;
    line-height: 40px;
    &--active {
      max-height: 100px;
      pointer-events: auto;
      opacity: 1;
      margin-top: 25px;
      transition: all, 0.4s ease-in;
    }
  }
  .event {
    padding: 15px;
    height: 175px;
    box-sizing: border-box;
    figure {
      position: relative;
      overflow: hidden;
      border-radius: 25px;
      img {
        width: 100%;
        height: 145px;
      }
    }
    .detail {
      font-size: 20px;
      .title {
        font-family: $font-family-2;
        color: dark-neutral(0);
        margin-bottom: 10px;
      }
      span {
        display: block;
      }
    }
    &.detail {
      height: auto;
    }
  }
  &--home {
    height: 575px;
    .filter {
      display: flex;
      justify-content: space-between;
      margin: 15px 0;
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        li {
          position: relative;
          margin: 0 5px;
          button {
            padding: 0 15px;
            margin: 0;
            min-width: fit-content;
            min-height: fit-content;
            line-height: 40px;
            background: transparent;
            color: light-neutral(0);
            font-size: 20px;
            box-shadow: none !important;
            &:hover {
              background-color: primary(500);
            }
            &:focus,
            &:target {
              background-color: primary(400);
            }
            &.active {
              background-color: primary(100);
            }
          }
          ul {
            position: absolute;
            background-color: light-neutral(0);
            padding: 15px;
            box-sizing: border-box;
            border-radius: 15px;
            display: block;
            left: 50%;
            transform: translateX(-50%);
            z-index: 9;
            li {
              margin: 5px 0;
              width: 100%;
              button {
                width: 100%;
                background: primary(500);
              }
            }
          }
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      a {
        padding: 0 15px;
        margin: 0;
        line-height: 40px;
        color: light-neutral(0);
        font-size: 20px;
        font-weight: bold;
        transition: all, 0.4s ease-out;
        border-radius: 15px;
        &:hover {
          background: primary(500);
          transition: all, 0.4s ease-in;
        }
      }
    }
    .event {
      padding: 15px;
      height: 175px;
      box-sizing: border-box;
      figure {
        position: relative;
        overflow: hidden;
        border-radius: 25px;
        border: 2px solid light-neutral(0);
        img {
          width: 100%;
          height: 145px;
        }
      }
      .detail {
        font-size: 20px;
        .title {
          font-family: $font-family-2;
          color: light-neutral(0);
          margin-bottom: 10px;
        }
        span {
          display: block;
        }
      }
    }
  }
}

@media #{$media-md} {
  .events-list {
    &--home {
      height: 350px;
      .event {
        .detail {
          .title {
            margin: 15px 0;
          }
        }
      }
    }
  }
}

@media #{$media-sm} {
  .events-list {
    width: 100%;
    height: auto;
    .event {
      &.detail {
        .detail {
          .title {
            margin: 15px 0;
          }
        }
      }
    }
    &--home {
      .event {
        height: auto;
        .detail {
          padding: 15px 0;
        }
      }
    }
  }
}
