.characters {
  position: relative;
  padding: 200px 0;
  margin: -25px 0 -25px 0;
  background-size: cover;
  .container {
    position: relative;
  }
  .swiper {
    overflow-x: clip;
    position: relative;
    overflow-y: inherit;
    z-index: 4;
    &-slide {
      width: 430px; //374
      position: relative;
      opacity: 0;
      transition: all, .4s ease-in-out;
      transform: translate3d(0px, 0px, -850px) rotateX(0deg) rotateY(0deg) scale(1) !important;
      &-shadow-left,
      &-shadow-right {
        display: none;
      }
      &-prev, &-next {
        transform: translate3d(0px, 0px, -500px) rotateX(0deg) rotateY(0deg) scale(1) !important;
      }
      &-active {
        transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
      }
      &-visible {
        opacity: 1;
      }
    }
  }
  .slider-arrow {
    background-color: light-neutral(0);
    width: 200px;
    height: 295px;
    box-sizing: border-box;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
    z-index: 3;
    &.swiper-button {
      &-prev {
        left: -40px;
        border-top-left-radius: 70px;
        border-bottom-left-radius: 70px;
        &::after {
          position: absolute;
          left: -25px;
        }
      }
      &-next {
        right: -40px;
        border-top-right-radius: 70px;
        border-bottom-right-radius: 70px;
        &::after {
          position: absolute;
          right: -25px;
        }
      }
    }
    &::after {
      font-size: 100px;
      color: primary(500);
      font-weight: bold;
      opacity: 0.25;
      transition: all, 0.4s;
    }
    &:hover {
      &::after {
        opacity: 1;
        transition: all, 0.4s;
      }
    }
  }
  .shape {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 2;
    &--left {
      height: 1600px;
      left: -50%;
    }
    &--right {
      right: -43%;
      top: 75%;
    }
    &--bg {
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
      top: 0 !important;
      left: 0 !important;
      transform: none !important;
      z-index: -1 !important;
    }
  }
  .slick {
    &-track {
      padding: 100px 0;
    }
    &-slide {
      transform: scale(0.6);
      transition: transform 0.4s;
      position: relative;
      opacity: 0;
      z-index: 2;
      &.slick-active {
        transform: scale(0.6) translate(400px);
        opacity: 1;
      }
      &.slick-active + .slick-slide {
        transform: scale(0.8) translate(85px) !important;
      }
      &.slick-center + .slick-slide {
        transform: scale(0.8) translate(-85px) !important;
        z-index: 10;
      }
      &.slick-center + .slick-slide + .slick-slide {
        transform: scale(0.6) translate(-400px) !important;
        z-index: 5;
      }
      &.slick-active + .slick-slide + .slick-center {
        transform: scale(1) !important;
        z-index: 30;
      }
    }
    &-navigation {
      .arrow-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 200px;
        height: 295px;
        background-color: light-neutral(0);
        z-index: 1;
        color: primary(500);
        &.next {
          right: 10%;
        }
        &.prev {
          left: 10%;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1368px) {
  .characters {
    .container {
      padding: 0 88px;
    }
    .swiper {
      &-slide {
        width: 380px;
      }
    }
    .shape {
      &--left {
        height: auto;
        left: -75%;
      }
      &--right {
        right: -70%;
      }
    }
    .slider-arrow {
      &.swiper-button-prev {
        left: 40px;
      }
      &.swiper-button-next {
        right: 40px;
      }
    }
  }
}

@media #{$media-lg} {
  .characters {
    padding: 300px 50px 250px 50px;
    .shape {
      width: 100%;
      top: 34%;
      height: auto;
      &--left {
        left: -70%;
      }
      &--right {
        right: -70%;
      }
    }
    .swiper {
      &-slide {
        width: 100%;
      }
    }
    .slider-arrow {
      background-color: light-neutral(0);
      width: 50px;
      height: 50px;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      z-index: 5;
      &.swiper-button {
        &-prev {
          left: -25px;
          &::after {
            position: absolute;
            left: auto;
          }
        }
        &-next {
          right: -25px;
          &::after {
            position: absolute;
            right: auto;
          }
        }

      }
      &::after {
        font-size: 25px;
        color: primary(300);
        font-weight: bold;
        opacity: 0.25;
        transition: all, 0.4s;
      }
      &:hover {
        &::after {
          opacity: 1;
          transition: all, 0.4s;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .characters {
    padding: 150px 50px 150px 50px;
    .shape {
      width: 100%;
      top: 34%;
      height: auto;
      &--left {
        left: -70%;
      }
      &--right {
        right: -70%;
      }
    }
    .swiper {
      &-slide {
        width: 100%;
      }
    }
    .slider-arrow {
      background-color: light-neutral(0);
      width: 50px;
      height: 50px;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      &.swiper-button {
        &-prev {
          left: -25px;
        }
        &-next {
          right: -25px;
        }
      }
      &::after {
        font-size: 25px;
        color: primary(300);
        font-weight: bold;
        opacity: 0.25;
        transition: all, 0.4s;
      }
      &:hover {
        &::after {
          opacity: 1;
          transition: all, 0.4s;
        }
      }
    }
  }
}
